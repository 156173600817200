import { Store } from 'vuex'
import { getInitialState } from './resetState'

export interface RootState {
  user: any
  swipe: any
  snackbar: any
  session: any
  files: any
  audit: any
}

export const createResetPlugin = () => {
  const initialState = getInitialState()
  return (store: Store<RootState>) => {
    store.subscribe((mutation, state) => {
      if (mutation.type === 'session/SET_STUDENT_LOGOUT') {
        Object.keys(initialState).forEach(moduleName => {
          store.commit(`${moduleName}/RESET_MODULE`)
        })
      }
    })
  }
}