import { Apartment, Appliance, Doc, User } from "@/types/types";
import { Commit } from "vuex";

interface State {
  likedApartments:Apartment[];
  appliances:Appliance[];
}

export const UserStore = {
  state:() => ({
    appliances: [],
    details: {},
    likedApartments: [] as Apartment[],
    didFollowTutorial: false,
    askedToGiveFeedback: false,
    totalApartmentsSeen: 0,
  }),

  getters : {
    getApplianceFromApartment: (state:State) =>  (aptId:string) => state.appliances.find(e => e.apartmentId._id == aptId),
    getAppliances : (state:any) => state.appliances,
    getRepliedAppliances : (state:State) => state.appliances.filter(appliance => ['ACCEPTED','DECLINED'].includes(appliance.status)),
    getUserId : (state:any) => state.details._id,
    getUserPresentation : (state:any) => state.details.presentation,
    getDetails : (state:any) => state.details,
    getLikedApartments : (state:any) => state.likedApartments,
    getFollowedTutorial : (state:any) => state.details.didFollowTutorial,
    showTicketPage : (state:any) => state.askedToGiveFeedback===false && state.totalApartmentsSeen === 5,
    getPreferences: (state:any) => state.details.preferences,
    getAvatar: (state:any) => state.details.avatar,
  },

  actions : {
    // login
    setLikedApartments({commit}:{commit:Commit}, payload:Apartment[]){
      commit('SET_LIKED_APARTMENTS', payload);
    },
    setAppliances({commit}:{commit:Commit}, payload:Appliance[]){
      commit('SET_APPLIANCES', payload);
    },
    setDetails({commit}:{commit:Commit}, payload:any){
      console.log(payload)
      commit('SET_DETAILS', payload);
      commit('SET_FEEDBACK', payload.askedToGiveFeedback)      
      if (payload.askedToGiveFeedback) return;
      const {appliedApts, declinedApts, likedApts} = payload;
      const allApartmentsSeen  = appliedApts.length  + declinedApts.length  + likedApts.length
      commit('SET_APARTMENTS_SEEN', allApartmentsSeen);
    },
    setUserAvatarUrl({commit}:{commit:Commit}, payload:any) {
      commit('SET_USER_AVATAR', payload)
    },

    // browse
    removeApartmentFromLikes({commit}:{commit:Commit}, payload:string){
      commit('REMOVE_APARTMENT_FROM_LIKES', payload);
    },
    postAppliance({commit}:{commit:Commit}, payload:Appliance){
      commit('UPDATE_APPLIED_APARTMENTS', payload);
      commit('REMOVE_APARTMENT_FROM_LIKES', payload.apartmentId._id);
    },
    setFeedbackBooleanToTrue({commit}:{commit:Commit}){
      commit('SET_FEEDBACK_TO_TRUE');
    },
    updateProfile({ commit }: { commit: Commit }, updatedProfile: User) {
      commit('SET_DETAILS', updatedProfile)
    },
    toggleFavorite({commit}:{commit:Commit}, payload:string){
      commit('TOGGLE_FAVORITE', payload);
    },
  },

  mutations : {
    // login
    SET_LIKED_APARTMENTS(state:any, payload:Apartment[]){
      state.likedApartments = payload;
    },
    SET_APPLIANCES(state:any, payload:Appliance[]){
      state.appliances = payload;
    },
    SET_DETAILS(state:any, payload:any){
      state.details = payload;
    },
    SET_APARTMENTS_SEEN(state:any, payload:number){
      state.totalApartmentsSeen = payload;
    },
    SET_USER_AVATAR(state:any, payload:any) {
      state.details.avatar = payload;
    },

    // browse
    UPDATE_LIKED_APARTMENTS(state:any, apartment:Apartment){
      state.likedApartments.push(apartment);
    },
    TOGGLE_FAVORITE(state:State, apartment:string){
      const index = state.likedApartments.findIndex(
        likedApt => likedApt._id.toString() === apartment
      );
      if (index !== -1) {
        state.likedApartments[index].isFaved = !state.likedApartments[index].isFaved
      };
    },
    REMOVE_APARTMENT_FROM_LIKES(state:State, payload:string){
      const index = state.likedApartments.findIndex(
        apartment => apartment._id.toString() === payload
      );
      if (index !== -1) state.likedApartments.splice(index, 1);
    },
    INCREMENT_APARTMENTS_SEEN(state:any){
      state.totalApartmentsSeen += 1;
    },
    UPDATE_APPLIED_APARTMENTS(state:any, payload:Appliance){
      state.appliances.push(payload);
    },
    SET_FEEDBACK_TO_TRUE(state:any){
      state.details.askedToGiveFeedback = true;
    },
    SET_FEEDBACK(state:any, feedback:boolean){
      state.details.askedToGiveFeedback = feedback
    },
    // SET_USER_LOGOUT(state:any){
    //   state.appliances = []
    //   state.details = {}
    //   state.likedApartments = []
    //   state.didFollowTutorial = false
    //   state.askedToGiveFeedback = false
    //   state.totalApartmentsSeen = 0
    // },
  }
}