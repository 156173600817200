import { Commit } from "vuex";

export const SessionStore = {
  state: () => ({
    loggedIn : false,
    accessToken : '',
    refreshToken : '',
  }),

  getters: {
    isLoggedIn: (state:any) => state.loggedIn,
    getToken: (state:any) => state.accessToken,
  },

  actions: {
    logIn({commit}:{commit:Commit}, auth:any){
      commit('SET_STUDENT_LOGIN', auth);
    },
    logOut({commit}:{commit:Commit}){
      // commit('SET_STUDENT_LOGOUT');
      // commit('SET_USER_LOGOUT');
      // commit('SET_SWIPE_LOGOUT');
      // commit('SET_FILES_LOGOUT');
    }
  },

  mutations: {
    SET_STUDENT_LOGIN(state:any, auth:any){
      state.loggedIn = true;
      state.accessToken = auth.accessToken;
      state.refreshToken = auth.refreshToken;
    },
    // SET_STUDENT_LOGOUT(state:any){
    //   state.loggedIn = false;
    //   state.accessToken = '';
    //   state.refreshToken = '';
    // }
  }
}