import store from '@/store/index';

const STATUS_CODES = {
  SUCCESS:200,
  CREATED:201,
  FORBIDDEN:403,
  ERROR:500
}

export const infoSnackbarBuilder = (errors: string[]) => {
  // const text = errors.join('\n'); 
  store.dispatch('setSnackbar', {
    color: 'alert', 
    text: errors
  });
};

export const snackbarBuilder = (code:number, shouldOnlyShowErrors:boolean, action:string) => {
  const color = [STATUS_CODES.SUCCESS, STATUS_CODES.CREATED].includes(code)
    ? "success" : "alert"
  if (color !== 'alert' && shouldOnlyShowErrors) return;
  const text = [TEXTS[action]?.[code] ?? String(code)];
  store.dispatch('setSnackbar', {color,text})
}

const TEXTS:Record<string,Record<number,string>> = {
  signup : {
    400 : "Requête impossible à traiter",
    404 : "Utilisateur introuvable.",
    409 : "Cet email est déjà utilisé.",
    500 : "Une erreur s'est produite."
  },
  login : {
    401 : "Mot de passe incorrect.",
    403 : "Mot de passe incorrect.",
    404 : "Utilisateur introuvable.",
  },
  modification : {
    200 : 'Modification enregistrée avec succès.',
    500 : "Une erreur s'est produite."
  },
  tickets : {
    201 : 'Votre retour a bien été pris en compte !',
    500 : "Une erreur s'est produite."
  },
  appliances : {
    201 : "Votre candidature a bien été envoyée !",
    400 : "Une erreur s'est produite.",
    401 : "Vous n'êtes pas autorisé à faire ça.",
    403 : "Vous n'êtes pas autorisé à faire ça.",
    404 : "Appartement introuvable.",
    500 : "Une erreur s'est produite."
  }
}