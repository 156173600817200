import { createStore } from "vuex";
import { SnackbarStore } from "./modules/snackbar";
import { SessionStore } from "./modules/session";
import { UserStore } from "./modules/user";
import { SwipeStore } from "./modules/swipe";
import { FileStore } from "./modules/files";
import { AuditStore } from "./modules/audit";
import { TourStore } from "./modules/tour";
import { createResetPlugin, RootState } from "./modules/resetPlugin";

const addResetMutation = (module: any) => {
  const originalState = module.state();
  return {
    ...module,
    mutations: {
      ...module.mutations,
      RESET_MODULE(state: any) {
        const newState = JSON.parse(JSON.stringify(originalState));
        Object.keys(newState).forEach(key => {
          state[key] = newState[key];
        });
      }
    }
  };
};

const store = createStore<RootState>({
  plugins: [createResetPlugin()],
  modules: {
    snackbar: addResetMutation(SnackbarStore),
    session: addResetMutation(SessionStore),
    user: addResetMutation(UserStore),
    swipe: addResetMutation(SwipeStore),
    files: addResetMutation(FileStore),
    audit: addResetMutation(AuditStore),
    tour: addResetMutation(TourStore)
  }
});

export default store;