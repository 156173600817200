import { Apartment } from "@/types/types";
import { Commit } from "vuex";

interface TourState {
  tourSteps:{targetId:string, text:string}[]
  currentStep:number
  shouldShowTutorial:boolean
  canStartTour:boolean
}

// const deleteCaches = async () => {
//   try {
//     const keys = await window.caches.keys();
//     await Promise.all(keys.map(key => caches.delete(key)));
//   } catch (err) {
//     console.log('deleteCache err: ', err);
//   }
// }

// const resetCacheForUpdate = () => {
//   if (!localStorage.getItem('cacheReset')) {
//     deleteCaches().then(_ => localStorage.setItem('cacheReset', 'yes')) 
//   }
// }

export const TourStore = {
  state:() => ({
    canStartTour:false, // false
    tourSteps: [
      { nextButton:'', targetId: 'tour-like', text: 'Like cet apartmenent.' },
      { nextButton:'', targetId: 'tour-dislike', text: 'Dislike celui-ci.' },
      { nextButton:'Suivant', targetId: 'tour-score', text: "Ceci est ton score de comptabilité entre l'apartement et tes critères de recherche." },
      { nextButton:'', targetId: 'tour-expand', text: 'Avec ce bouton tu peux en voir plus sur l\'appartement.' },    
      { nextButton:'', targetId: 'tour-return-expand', text: 'Maintenant reviens sur tes pas.' }, 
      { nextButton:'', targetId: 'tour-selection-icon', text:'Maintenant vas voir dans tes likes' },
      { nextButton:'', targetId: 'tour-selection-candidate-0', text:'Ici, tu peux candidater pour un apartement que tu as liké' },
      { nextButton:'Suivant', targetId: 'tour-candidate-0', text:'Cet apartement est en partenariat avec Keysper, tu peux donc candidater directement.'},
      // { nextButton:'', targetId: 'tour-selection-candidate-1', text:'Cet apartement n\'est pas en partenariat avec Keysper, mais tu peux candidater sur le site.' },
      { nextButton:'', targetId: 'tour-profile-icon', text:'Maintenant clique en bas sur ton profil.' },
      { nextButton:'Compris!', targetId: 'tour-my-score', text:'Ici c\'est ton score' },
      { nextButton:'', targetId: 'tour-my-profile', text:'Ici c\'est ton profil.'},
      { nextButton:'', targetId: 'tour-my-search', text:'ici c\'est ta recherche.'},
      { nextButton:'', targetId: 'tour-my-documents', text:'ici c\'est tes documents.'}
    ],
    currentStep: 0,
    shouldShowTutorial: false // false
  }),

  getters: {
    canStartTour: (state:TourState) => state.canStartTour,
    showTutorial: (state:TourState) => state.shouldShowTutorial,
    tourIsOn: (state:TourState) => state.canStartTour && state.shouldShowTutorial,
    getTourSteps: (state:TourState) => state.tourSteps,
    getCurrentStep: (state:TourState) => state.currentStep,
    getStep: (state:TourState) => state.tourSteps[state.currentStep],
    isRightStep: (state:TourState) => (elmId:string) => state.tourSteps[state.currentStep].targetId === elmId
  },

  actions: {
    setStartTour({commit}:{commit:Commit}){
      commit('START_TOUR');
    },
    setNextTourStep({commit}:{commit:Commit}){
      commit('INCREMENT_CURRENT_TOUR_STEP');
    }
  },

  mutations: {
    START_TOUR(state:TourState){
      if (state.canStartTour){
        state.shouldShowTutorial = true;
        state.currentStep = 0;
      }
    },
    INCREMENT_CURRENT_TOUR_STEP(state:TourState){
      state.currentStep++;
      if (state.currentStep >= state.tourSteps.length){
        state.shouldShowTutorial = false;
      }
    }
  }
}